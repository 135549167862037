var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-fare-rules-".concat(_vm.id),
      "title": _vm.$t('flight.fareRule'),
      "title-class": "text-airline font-medium-4 font-weight-bolder",
      "centered": "",
      "size": "lg",
      "no-close-on-backdrop": "",
      "busy": _vm.loading
    },
    on: {
      "show": _vm.openModalHandle
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn(_ref) {
        var cancel = _ref.cancel;
        return [_c('b-button', {
          attrs: {
            "variant": "secondary"
          },
          on: {
            "click": cancel
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")])];
      }
    }])
  }, [_c('b-tabs', {
    attrs: {
      "pills": ""
    },
    scopedSlots: _vm._u([{
      key: "tabs-end",
      fn: function fn() {
        return [_c('div', {
          staticClass: "flex-fill d-flex-center justify-content-end flex-wrap"
        }, [['VN1A', 'VU', 'QH', 'VJ'].includes(_vm.trip.source) || ['F1'].includes(_vm.trip.source) && _vm.airlineHaveLinkFareRule.includes(_vm.trip.airline) ? _c('b-button', {
          staticClass: "px-75 py-50 rounded-lg text-nowrap",
          staticStyle: {
            "text-decoration": "underline"
          },
          attrs: {
            "variant": "flat-info"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              $event.stopPropagation();
              return _vm.openLinkFareRules(_vm.trip.source, _vm.trip.airline);
            }
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('flight.linkFareRule')) + " ")]) : _vm._e()], 1)];
      },
      proxy: true
    }])
  }, [_vm.sourceShowTabFareRuleShorten.includes(_vm.trip.source) ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('feather-icon', {
          staticClass: "m-0",
          attrs: {
            "icon": "FileTextIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t('flight.fareRules.title')))])], 1)];
      },
      proxy: true
    }], null, false, 826613337)
  }, [_c('IAmOverlay', {
    attrs: {
      "loading": _vm.loading
    },
    on: {
      "update:loading": function updateLoading($event) {
        _vm.loading = $event;
      }
    }
  }, [_c('app-collapse', {
    staticStyle: {
      "max-height": "70vh",
      "overflow": "auto",
      "line-height": "24px",
      "white-space": "pre",
      "font-family": "Calibri, sans-serif"
    },
    attrs: {
      "type": "border"
    }
  }, _vm._l(_vm.fareRuleTextFromAirline, function (fareRuleItem, fareRuleIndex) {
    return _c('app-collapse-item', {
      key: fareRuleIndex,
      attrs: {
        "title": ""
      },
      scopedSlots: _vm._u([{
        key: "header",
        fn: function fn() {
          return [_c('span', {
            staticClass: "text-heading-4 text-uppercase fw-700",
            class: "".concat(fareRuleItem[0].includes('PENALTIES') ? 'text-danger' : 'text-dark')
          }, [_vm._v(" " + _vm._s(fareRuleItem[0]) + " ")])];
        },
        proxy: true
      }], null, true)
    }, [_c('div', {
      staticClass: "position-relative ml-50"
    }, [!_vm.loading ? _c('b-button', {
      directives: [{
        name: "ripple",
        rawName: "v-ripple.400",
        value: 'rgba(113, 102, 240, 0.15)',
        expression: "'rgba(113, 102, 240, 0.15)'",
        modifiers: {
          "400": true
        }
      }],
      staticClass: "position-absolute p-75 rounded-circle m-25",
      staticStyle: {
        "top": "0",
        "right": "0",
        "z-index": "9999"
      },
      attrs: {
        "variant": "flat-secondary",
        "disabled": _vm.disabledCopy
      },
      on: {
        "click": function click($event) {
          _vm.copyFareRuleText(fareRuleItem.join('\n'));
        }
      }
    }, [_c('feather-icon', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover.window",
        modifiers: {
          "hover": true,
          "window": true
        }
      }],
      staticClass: "text-dark",
      attrs: {
        "icon": "CopyIcon",
        "size": "21",
        "title": _vm.$t('reservation.shortenBooking.copyFareRules')
      }
    })], 1) : _vm._e(), _vm._l(fareRuleItem, function (textItem, textIndex) {
      return _c('div', {
        key: textIndex,
        staticClass: "text-wrap",
        domProps: {
          "innerHTML": _vm._s(textItem.replace(/\d+/g, '<strong class=text-info>$&</strong>'))
        }
      });
    })], 2)]);
  }), 1), _vm.loading ? _c('div', [_vm._v(" Đang tải... ")]) : !(_vm.fareRuleTextFromAirline && _vm.fareRuleTextFromAirline.length) ? _c('div', [_vm._v(" Không có điều kiện vé rút gọn từ hãng ")]) : _vm._e()], 1)], 1) : _vm._e(), _vm.sourceShowTabFareRulesCommon.includes(_vm.trip.source) ? _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center gap-1"
        }, [_c('feather-icon', {
          staticClass: "m-0",
          attrs: {
            "icon": "BriefcaseIcon",
            "size": "20"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v(_vm._s(_vm.$t('flight.fareRules.titleFromAirline')))])], 1)];
      },
      proxy: true
    }], null, false, 943755973)
  }, [_c('BCard', {
    staticClass: "p-1 m-0",
    staticStyle: {
      "line-height": "1.7"
    },
    attrs: {
      "no-body": ""
    }
  }, [_c('div', {
    staticClass: "d-flex font-weight-bold"
  }, [!_vm.trip.domestic ? _c('div', {
    staticClass: "text-truncate d-flex-center"
  }, [['1G', 'AK', 'TH', '1A'].includes(_vm.trip.source) ? _c('b', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm.resolveBaggage1GAKComputed.carryBag ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.resolveBaggage1GAKComputed.carryBag) + " ")], 1) : _vm._e(), _vm.resolveBaggage1GAKComputed.checkinBag || !['1G', '1A'].includes(_vm.trip.source) ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": !_vm.resolveBaggage1GAKComputed.checkinBag
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.resolveBaggage1GAKComputed.checkinBag || 'Không bao gồm') + " ")], 1) : _vm._e()]) : _vm._e(), ['VN1A'].includes(_vm.trip.source) ? _c('b', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm.resolveBaggageVN1A(_vm.fareOption.baggageAllowances).carryBag ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.resolveBaggageVN1A(_vm.fareOption.baggageAllowances).carryBag) + " ")], 1) : _vm._e(), _vm.resolveBaggageVN1A(_vm.fareOption.baggageAllowances).checkinBag ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": !_vm.resolveBaggageVN1A(_vm.fareOption.baggageAllowances).checkinBag
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.resolveBaggageVN1A(_vm.fareOption.baggageAllowances).checkinBag) + " ")], 1) : _vm._e()]) : _vm._e(), ['VU'].includes(_vm.trip.source) ? _c('b', {
    staticClass: "text-dark font-weight-bold"
  }, [_vm.fareRulesAirline('VU', _vm.fareOption.fareType).boardingBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareOption.fareType).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline('VU', _vm.fareOption.fareType).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VU', _vm.fareOption.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VU', _vm.fareOption.fareType).checkinBaggage) + " ")], 1) : _vm._e()]) : _vm._e(), ['VJ'].includes(_vm.trip.source) ? _c('b', [[_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode].some(function (item) {
    return _vm.iataCodeVJ2.includes(item);
  }) ? _c('div', [_vm.fareRulesAirline('VJ_2', _vm.fareOption.groupClass).boardingBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareOption.groupClass).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline('VJ_2', _vm.fareOption.groupClass).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VJ_2', _vm.fareOption.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_2', _vm.fareOption.groupClass).checkinBaggage) + " ")], 1) : _vm._e()]) : [_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode].some(function (item) {
    return _vm.iataCodeVJ3.includes(item);
  }) ? _c('div', [_vm.fareRulesAirline('VJ_3', _vm.fareOption.groupClass).boardingBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareOption.groupClass).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline('VJ_3', _vm.fareOption.groupClass).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VJ_3', _vm.fareOption.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ_3', _vm.fareOption.groupClass).checkinBaggage) + " ")], 1) : _vm._e()]) : _c('div', [_vm.fareRulesAirline('VJ', _vm.fareOption.groupClass).boardingBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareOption.groupClass).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline('VJ', _vm.fareOption.groupClass).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('VJ', _vm.fareOption.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('VJ', _vm.fareOption.groupClass).checkinBaggage) + " ")], 1) : _vm._e()])]) : _vm._e(), ['QH'].includes(_vm.trip.source) ? _c('b', [_vm.fareRulesAirline(_vm.resolveQHInternational(_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode), _vm.fareOption.fareType).boardingBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline(_vm.resolveQHInternational(_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode), _vm.fareOption.fareType).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline(_vm.resolveQHInternational(_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode), _vm.fareOption.fareType).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline(_vm.resolveQHInternational(_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode), _vm.fareOption.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline(_vm.resolveQHInternational(_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode), _vm.fareOption.fareType).checkinBaggage) + " ")], 1) : _vm._e()]) : _vm._e()]) : _c('div', {
    staticClass: "text-truncate d-flex-center"
  }, [_vm.trip.source === 'QH' && [_vm.trip.departure.IATACode, _vm.trip.arrival.IATACode].includes('VCS') ? _c('b', [_vm.fareRulesAirline('QH_VCS', _vm.fareOption.fareType).boardingBaggage && _vm.fareRulesAirline('QH_VCS', _vm.fareOption.fareType).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareOption.fareType).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline('QH_VCS', _vm.fareOption.fareType).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline('QH_VCS', _vm.fareOption.fareType).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline('QH_VCS', _vm.fareOption.fareType).checkinBaggage) + " ")], 1) : _vm._e()]) : _c('b', [_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).boardingBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('feather-icon', {
    staticClass: "text-warning",
    staticStyle: {
      "margin": "2px"
    },
    attrs: {
      "icon": "BriefcaseIcon",
      "size": "20"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.boardingBaggage')) + ": " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).boardingBaggage) + " ")], 1) : _vm._e(), _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).checkinBaggage ? _c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('LuggageIcon', {
    attrs: {
      "no-luggage": ['NONE', 'Không bao gồm'].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).checkinBaggage)
    }
  }), _vm._v(" " + _vm._s(_vm.$t('flight.checkinBaggage')) + ": " + _vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).checkinBaggage) + " ")], 1) : _vm._e()])])]), _c('div', {
    staticClass: "d-flex font-weight-bold"
  }, [['1G', 'AK'].includes(_vm.trip.source) ? _c('div', [_vm.fareOption.cancelPenalties && _vm.fareOption.cancelPenalties.length && !_vm.fareOption.cancelPenalties[0].nonRefundable ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.refundableAllow')) + " ("), _c('span', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareRules.contactBooker')))]), _vm._v("). ")])], 1) : _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : ['1A', '1B'].includes(_vm.trip.source) ? _c('div', [_vm.fareOption.refundPenalties && _vm.fareOption.refundPenalties.length && !_vm.fareOption.refundPenalties[0].nonRefundable ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.refundableAllow')) + " ("), _c('span', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareRules.contactBooker')))]), _vm._v("). ")])], 1) : _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : _vm.trip.domestic ? _c('div', [(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass) ? !['', 'NONE', false].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).refund) : !_vm.fareOption.noRefund) ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-refund.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.trip.source !== 'VJ' ? _vm.$t('flight.fareRules.refundable') : _vm.$t('flight.fareRules.refundableIdentify')) + ": "), _c('span', [_vm._v(_vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass) ? _vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).refund : _vm.$t('flight.fareRuleTimeStatus.refundable')))])])], 1) : _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-refund.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noRefundable')) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex font-weight-bold"
  }, [['1G', 'AK', '1A', '1B'].includes(_vm.trip.source) ? _c('div', [_vm.fareOption.changePenalties && _vm.fareOption.changePenalties.length && !_vm.fareOption.changePenalties[0].nonRefundable ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-change.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.reschedulableAllow')) + " ("), _c('span', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareRules.contactBooker')))]), _vm._v("). ")])], 1) : _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-change.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noReschedulable')) + " ")])], 1)]) : _vm.trip.domestic ? _c('div', [!['NONE', false, ''].includes(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).reschedule) ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-change.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.reschedulable')) + ": "), _c('span', [_vm._v(_vm._s(_vm.fareRulesAirline(_vm.trip.segments[0].operating || _vm.trip.airline, ['QH', 'VU'].includes(_vm.trip.source) ? _vm.fareOption.fareType : _vm.fareOption.groupClass).reschedule))])])], 1) : _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-change.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t('flight.fareRules.noReschedulable')) + " ")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "d-flex font-weight-bold"
  }, [['1G'].includes(_vm.trip.source) ? _c('div', [_vm.fareOption.noshowPenalties && _vm.fareOption.noshowPenalties.length && !_vm.fareOption.noshowPenalties[0].nonRefundable ? _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/can-change.svg")
    }
  }), _c('span', [_vm._v(" " + _vm._s(_vm.$t("flight.fareRuleTimeStatus.noshow")) + ": " + _vm._s(_vm.$t('flight.fareRules.allow')) + " ("), _c('span', {
    staticClass: "fw-700 text-airline"
  }, [_vm._v(_vm._s(_vm.$t('flight.fareRules.contactBooker')))]), _vm._v("). ")])], 1) : _c('div', {
    staticClass: "d-flex-center"
  }, [_c('b-img', {
    staticClass: "mr-25",
    staticStyle: {
      "height": "24px"
    },
    attrs: {
      "blank-color": "#ccc",
      "src": require("@/assets/other-icon/no-change.svg")
    }
  }), _c('span', {
    staticClass: "text-danger"
  }, [_vm._v(" " + _vm._s(_vm.$t("flight.fareRuleTimeStatus.notNoshow")) + " ")])], 1)]) : _vm._e()])])], 1) : _vm._e()], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }